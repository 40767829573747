<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <div class="banner">

            <video src="https://static.ycsd.work//site/ai/dps1.mp4" muted autoplay loop></video>

            <div class="banner-content">
                <img class="banner-title" ondragstart="return false" src="@/assets/images/fashion/sd_banner_text.png"
                     alt="">

                <div class="banner-buttons">

                    <a class="banner-button">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-1.png" alt="">
                        开课日期
                    </a>

                    <a class="banner-button">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-2.png" alt="">
                        咨询课程
                    </a>


                    <a class="banner-button" href="/sign-up-process">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-3.png" alt="">
                        报名流程
                    </a>

                </div>
            </div>
        </div>

        <div class="cla-container">
            <div>
                <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/sd_cla_1.png" alt="">
            </div>
            <div>
                <img class="content-img" ondragstart="return false"
                     src="https://static.ycsd.work/site/fashion/sd_cla_2.png" alt="">
            </div>
        </div>

        <div class="ofl-container">
            <img class="content-img i1" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-1.png" alt="">
            <img class="content-img i2" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-2.png" alt="">
            <img class="content-img i3" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-3.png" alt="">
        </div>


        <l-spc></l-spc>

        <l-schedule :schedule="schedule"></l-schedule>

        <l-teacher></l-teacher>

        <l-contact></l-contact>

        <l-footer></l-footer>
        <l-icp></l-icp>

    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LRightContact from "../../components/LRightContact";
    import LSpc from "../../components/LSpc";
    import LSchedule from "../../components/LSchedule";
    import LTeacher from "../../components/LTeacher";
    import LContact from "../../components/LContact";
    import meta from "../../meta";

    export default {
        name: "StylingDresser",
        components: {LContact, LTeacher, LSchedule, LSpc, LRightContact, LIcp, LFooter, MainNav},
        metaInfo: meta.appendTitle('时尚造型穿搭师'),
        data() {
            return {
                schedule: {
                    list: [
                        {
                            name: '造型穿搭师的角色价值',
                            en_name: 'The role value of the stylist',
                            time: 1,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.时尚造型穿搭师的角色价值及素养要求',
                                            sc: true
                                        }, {
                                            name: '2.时尚造型穿师的广泛工作应用场景',
                                        }, {
                                            name: '3.国际趋势对搭配师未来的影响',
                                            sc: true
                                        }, {
                                            name: '4.职业必备核心技能与自我 IP 价值',
                                        }
                                    ],
                                }, {
                                    name: '收获',
                                    list: [
                                        {
                                            name: '学员能够宏观了解行业现状及搭配师角色的重要性'
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '确立形象数据的思路及人衣服饰关系',
                            en_name: 'Establish the idea of image data and the relationship between people and clothes',
                            time: 2,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.形象DNA确立的结构化思维框架'
                                        },
                                        {
                                            name: '2.认识色彩DNA'
                                        },
                                        {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部明度&服饰深浅度的关系</small>'
                                        }, {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部纯度&服饰饱和度的关系</small>'
                                        }, {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部冷暖&服饰冷暖色的关系</small>'
                                        }, {
                                            name: '3.认识风格DNA'
                                        },
                                        {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部量感&着装廓形选择</small>'
                                        }, {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部轮廓&着装直曲剪裁选择</small>'
                                        }, {
                                            name: '<small>&nbsp;&nbsp;&nbsp;面部形态&着装个性度选择</small>'
                                        }
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            'name': '为自己分析自我形象数据，为身边1-2位朋友人工诊断形象数据并阐述逻辑拍成视频或者图文形式'
                                        }
                                    ]
                                }, {
                                    name: '课程亮点',
                                    list: [
                                        {
                                            'name': '用大量的中外明星、时尚博主、素人图片举例示范，带领学员了解形象DNA 6大属性的显性特征以及服饰的选择，了解两者之间的和谐匹配关系，并且用明星成功vs失败造型图片来直观明了的举例和分析。'
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '时尚造型-色彩搭配',
                            en_name: 'Fashion style-color matching',
                            time: 4,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.色彩搭配原理'
                                        }, {
                                            name: '2.掌握配色方法'
                                        }, {
                                            name: '3.认识8大颜创色彩类型及搭配方法'
                                        }, {
                                            name: '4.情感化色彩搭配应用'
                                        }, {
                                            name: '5.季节情感心理色彩应用'
                                        }, {
                                            name: '6.中外时尚博主明星造型分析-色彩'
                                        }
                                    ],
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '组合5个色彩搭配方法灵感板，1个季节情感色彩灵感板，给自己或朋友拍摄1个色彩类型造型视频，1个情感化色彩搭配视频。'
                                        }
                                    ]
                                },
                                {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '学员能够通过课程内容与练习掌握色彩的基础，色彩的搭配原理，色彩的搭配方法，学会8大色彩分类的服饰色彩搭配方法，并了解色彩心理学与季节、内心情感化造型需求，掌握服饰的色彩搭配应用。\n' +
                                                ' <br /> 讲师在点评中提升学生对搭配的敏锐度及洞察力。同时，提供对媒体、街拍资讯趋势的检索途径。'
                                        }
                                    ]
                                }

                            ],
                        },
                        {
                            name: '时尚造型-风格搭配',
                            en_name: 'Fashion style-style match',
                            time: 3,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.认识8大风格类型'
                                        }, {
                                            name: '2.风格与单品组合的关系'
                                        }, {
                                            name: '3.中外时尚博主明星造型分析-风格'
                                        },
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '打造自己的风格街拍照，为朋友打造1组风格街拍照或拍1个短视频。'
                                        }
                                    ]
                                }, {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '用大量的明星、博主、素人图片举例，看时尚博主街拍图了解8大风格的特征以及单品组合形式，掌握每个风格的特性与单品组合形式。'
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '时尚造型-面料搭配',
                            en_name: 'Fashion style-fabric matching',
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.中外时尚博主明星造型分析-面料',
                                        }, {
                                            name: '2.认识服装面料及视觉情感化特性',
                                        }, {
                                            name: '3.掌握面料基础搭配组合关系，学会应用面料来调节整体造型',
                                        }
                                    ]
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '剪贴面料组合关系灵感板，为朋友搭配整套look拍街照1组或短视频1个。'
                                        }
                                    ]
                                }, {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '带领学员了解熟知面料的语言及搭配方法，了解面料对性格和气质的可视化展现及心理情感关系。'
                                        }
                                    ]
                                }
                            ],
                            time: 2,
                            points: 20,
                        },
                        {
                            name: '时尚造型-图案纹样搭配',
                            en_name: 'Fashion modeling-pattern matching',
                            time: 2,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.中外时尚博主明星造型分析-图案',
                                        }, {
                                            name: '2.纹样图案的名称、由来及时尚起源',
                                        }, {
                                            name: '3.图案的搭配方法',
                                        }, {
                                            name: '4.图案和性格的融合关系',
                                        }
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '剪贴面料组合关系灵感板，运用图案知识为朋友搭配整套look拍街照1组或短视频1个'
                                        }
                                    ]
                                }, {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '带领学员了解图案的起源、名称、特性，学会解读图案传达的语言，并运用服饰心理学，解读了解图案和性格的融合关系。'
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '不同场合场景的应用化搭配',
                            en_name: 'Application collocation of different occasions',
                            time: 2,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.了解国际TPO着装原则，了解形象造型目标和社会层面之间的必要关系',
                                        }, {
                                            name: '2.商务职场的职业分类及造型目标和掌握风格造型&单品的融合',
                                        }, {
                                            name: '3.精致社交场合的场景分类及造型目标和风格造型&单品的融合'
                                        }, {
                                            name: '4.旅行度假场合的场景分类及造型目标和风格造型&单品的融合'
                                        }, {
                                            name: '5.影视剧造型分析-场合场景'
                                        }
                                    ]
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '给自己街拍或者朋友搭配四个场合的街拍照片1组或短视频一个',
                                            style: 2
                                        },
                                    ]
                                },
                                {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '通过影视剧造型分析场合着装原理及国际场合着装原则，分析各场合的角色造型目标及单品组合搭配，培养学员对场合着装的基础认知，掌握社会层面与形象造型的必要关系并灵活应用。',
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '2021/2022国际服饰最新流行趋势与元素表现',
                            en_name: 'The role value of the stylist',
                            time: 2,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.时尚流行趋势的起源及演变过程'
                                        }, {
                                            name: '2.2021年/2022年国际时装周视频解析'
                                        }, {
                                            name: '3.时装流行趋势之色彩表现'
                                        }, {
                                            name: '4.时装流行趋势之风格表现'
                                        }, {
                                            name: '4.时装流行趋势之面料表现'
                                        }, {
                                            name: '4.流行趋势之图案装饰表现'
                                        },
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '运用流行趋势知识为朋友搭配整套look拍街照1组或短视频1个',
                                            style: 2
                                        }
                                    ]
                                }, {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '通过了解时尚起源及演变过程，观看时装品牌走秀视频，培养学员对时尚的敏锐度和对流行趋势判断的敏感度，学会鉴赏时尚、过滤时尚，养成好的审美素养和眼光。',
                                            style: 2
                                        }
                                    ]
                                }
                            ]
                        },
                    ],
                    pdf: '',
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .banner {
        position: relative;
        height: 1080px;
        width: 100%;
        background-image: url("https://static.ycsd.work/site/fashion/sd_banner.png");
        background-size: contain;
        padding: 1px;
        overflow: hidden;

        video {
            width: 100%;
            height: auto;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
        }
    }

    .banner-content {
        width: 1200px;
        max-width: 100%;
        text-align: left;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .banner-title {
        margin-top: 240px;
        margin-bottom: 140px;
        width: 700px;
    }

    .banner-button {
        img {
            margin-right: 20px;
        }

        width: 284px;
        height: 120px;
        text-align: center;
        display: inline-block;
        background: #0D392E;
        line-height: 120px;

        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .cla-container {
        padding: 1px;

        .title-img {
            width: 470px;
            margin-top: 100px;
            margin-bottom: 60px;
        }

        .content-img {
            width: 1200px;
            max-width: 100%;
            margin-bottom: 100px;
        }
    }

    .ofl-container {
        padding: 1px;
        background-color: rgba(16, 16, 16, 1);
        text-align: center;

        img {
            display: inline-block;
            width: 1200px;
            max-width: 100%;
        }

        .i1 {
            margin-top: 150px;
            margin-bottom: 150px;
        }

        .i2 {
            margin-bottom: 133px;
        }

        .i3 {
            margin-bottom: 150px;
        }
    }
</style>