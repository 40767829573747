<template>
    <div class="teacher-container">
        <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/tea-t.png" title="讲师信息"
             alt="讲师信息">
        <div class="teachers">
            <div class="teacher" v-for="(teacher,tid) in teachers" :key="tid">
                <div class="teacher-photo">
                    <div class="teacher-photo-box" :title="teacher.name"
                         :style="`background-image: url(${teacher.avatar})`"
                    ></div>
                </div>
                <div class="teacher-content">
                    <div class="teacher-name">
                        {{ teacher.name }}
                        <span v-for="(title,ttid) in teacher.titles" class="title" :title="title" :key="ttid">
                            {{ title }}
                        </span>
                    </div>
                    <div class="teacher-sc">
                        {{ teacher.sc }}
                    </div>
                    <div class="teacher-su">
                        {{ teacher.su }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LTeacher",
        data() {
            return {
                teachers: [
                    {
                        avatar: 'https://static.ycsd.work/site/lal/tea_a_1.png',
                        name: '晋子墨',
                        titles: ['形象造型讲师'],
                        sc: '官媒新闻编辑出身/资深时尚买手/日本CT认证高级形象造型师',
                        su: '曾服务过众多一线时装品牌担任时尚形象造型讲师，为多个上市公司CEO、电视台主持人担任过形象造型策划师，有着数10年丰富的造型策划经验与行业授课经验。'
                    },
                    {
                        avatar: 'https://static.ycsd.work/site/lal/tea_a_1.png',
                        name: '晋子墨',
                        titles: ['形象造型讲师'],
                        sc: '官媒新闻编辑出身/资深时尚买手/日本CT认证高级形象造型师',
                        su: '曾服务过众多一线时装品牌担任时尚形象造型讲师，为多个上市公司CEO、电视台主持人担任过形象造型策划师，有着数10年丰富的造型策划经验与行业授课经验。'
                    }, {
                        avatar: 'https://static.ycsd.work/site/lal/tea_a_1.png',
                        name: '晋子墨',
                        titles: ['形象造型讲师'],
                        sc: '官媒新闻编辑出身/资深时尚买手/日本CT认证高级形象造型师',
                        su: '曾服务过众多一线时装品牌担任时尚形象造型讲师，为多个上市公司CEO、电视台主持人担任过形象造型策划师，有着数10年丰富的造型策划经验与行业授课经验。'
                    }
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
    .teacher-container {
        padding: 1px;
        background-image: url("../assets/images/fashion/teacher-bg.png");
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .title-img {
        width: 470px;
        margin-top: 150px;
        margin-bottom: 100px;
    }

    .teachers {
        width: 1200px;
        max-width: 100%;
        margin: 0 auto;
    }

    .teacher {
        display: flex;
        text-align: left;

        &:last-child .teacher-content {
            border-bottom: 0;
        }
    }

    .teacher-photo {
        margin-right: 30px;
    }

    .teacher-photo-box {
        width: 184px;
        height: 184px;
        border-radius: 50%;
        background-size: 100%;
    }

    .teacher-name {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        margin-bottom: 30px;

        .title {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            background: #E5E5E5;
            border-radius: 4px;
            padding: 5px 15px;
        }
    }

    .teacher-sc {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        margin-bottom: 20px;
    }

    .teacher-su {
        font-size: 20px;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
        margin-bottom: 55px;
    }

    .teacher-content {
        border-bottom: 1px solid #333333;
        margin-bottom: 50px;
    }

    .teachers {
        padding-bottom: 100px;
    }




</style>