<template>
    <div class="schedule-container">
        <div class="schedule" v-if="schedule">

            <img class="schedule-title" ondragstart="return false" src="@/assets/images/fashion/s-title.png" alt="">

            <div class="schedule-tools">
                <a @click="showAll" href="javascript:">查看全部课表</a>
                <a href="#">下载PDF表格</a>
            </div>

            <table>
                <template v-for="(item,index) in schedule.list">
                    <tr :key="index">
                        <td class="no">模块{{ transNo(index) }}</td>
                        <td class="name" @click="toggleItem(item)">
                            <div>{{ item['name'] }}</div>

                            <div v-if="item['en_name'].length < 70" class="en">{{ item['en_name'] }}</div>
                            <div v-else class="en"><small>{{ item['en_name'] }}</small></div>
                        </td>
                        <td class="time">{{ item['time'] }}课时</td>
                        <td>{{ item['points'] }}学分</td>
                    </tr>
                    <tr :key="`a${index}`" v-if="item.detail" v-show="item.show">
                        <td colspan="4" class="contents-td">
                            <div class="contents">
                                <div v-for="(content,cid) in item.detail" class="block" :key="cid">
                                    <div class="block-title">{{ content['name'] }}</div>
                                    <div class="block-content">

                                        <div v-for="(block,bid) in content.list" class="block-sub-title" :class="{
                                                long: ((block['name'].length > 30 && block['style'] !== 1) || block['style'] === 2)
                                            }" :key="bid">
                                            <span class="block-sub-title-text" v-html="block['name']"></span>
                                            <div v-if="block['sc']" class="sc-btns">
                                                <a class="sc-btn" href="#">实操</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>

        </div>

    </div>
</template>

<script>
    export default {
        name: "LSchedule",
        props: {
            schedule: Object
        },
        data() {
            return {
                /*schedule: {
                    list: [
                        {
                            name: '造型穿搭师的角色价值',
                            en_name: 'The role value of the stylist',
                            time: 1,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.时尚造型穿搭师的角色价值及素养要求',
                                            sc: true
                                        }, {
                                            name: '2.时尚造型穿师的广泛工作应用场景',
                                        }, {
                                            name: '3.国际趋势对搭配师未来的影响',
                                            sc: true
                                        }, {
                                            name: '4.职业必备核心技能与自我 IP 价值',
                                        }
                                    ],
                                }, {
                                    name: '收获',
                                    list: [
                                        {
                                            name: '学员能够宏观了解行业现状及搭配师角色的重要性'
                                        }
                                    ]
                                }
                            ],
                            show: true,
                        },
                        {
                            name: '确立形象数据的思路及人衣服饰关系',
                            en_name: 'Establish the idea of image data and the relationship between people and clothes',
                            time: 1,
                            points: 20
                        },
                        {
                            name: '时尚造型-色彩搭配',
                            en_name: 'Fashion style-color matching',
                            time: 4,
                            points: 20
                        }, {
                            name: '时尚造型-风格搭配',
                            en_name: 'Fashion style-style match',
                            time: 3,
                            points: 20
                        }, {
                            name: '时尚造型-面料搭配',
                            en_name: 'Fashion style-fabric matching',
                            time: 2,
                            points: 20
                        }, {
                            name: '时尚造型-图案纹样搭配',
                            en_name: 'Fashion modeling-pattern matching',
                            time: 2,
                            points: 20
                        }, {
                            name: '不同场合场景的应用化搭配',
                            en_name: 'Application collocation of different occasions',
                            time: 2,
                            points: 20
                        }, {
                            name: '2021/2022国际服饰最新流行趋势与元素表现',
                            en_name: 'The role value of the stylist',
                            time: 2,
                            points: 20
                        },
                    ],
                    pdf: '',
                }*/
            }
        },
        methods: {
            transNo(no) {

                let arr = [
                    '一',
                    '二',
                    '三',
                    '四',
                    '五',
                    '六',
                    '七',
                    '八',
                    '九',
                    '十',
                    '十一',
                    '十二',
                    '十三',
                    '十四',
                    '十五',
                    '十六',
                    '十七',
                    '十八',
                    '十九',
                    '二十',
                    '二十一',
                    '二十二',
                    '二十三',
                    '二十四'
                ];

                return arr[no] === undefined ? no : arr[no];
            },
            toggleItem(item) {
                item.show = !(item.show);
                this.$forceUpdate()
            },
            showAll() {
                this.schedule.list.map((item) => {
                    item.show = true
                });
                this.$forceUpdate()
            }
        }
    }
</script>

<style lang="less" scoped>
    .schedule-container {
        .schedule {
            width: 1200px;
            margin: 0 auto 200px;
        }

        padding: 1px;
        background-color: rgba(16, 16, 16, 1);
        background-image: url("https://static.ycsd.work/site/fashion/s-bg.png");
        background-size: contain;

        .schedule-title {
            width: 470px;
            margin-top: 150px;
            margin-bottom: 35px;
        }

        .schedule-tools {
            text-align: right;

            a {
                display: inline-block;
                background: #1F7762;
                font-size: 14px;
                font-weight: 400;
                color: #F9F9F9;
                line-height: 20px;
                border-radius: 2px;
                opacity: 0.9;
                border: 1px solid #1F7762;

                padding: 10px 20px;
                margin-bottom: 20px;
                margin-left: 20px;
            }
        }

        table {
            width: 1240px;
            border-collapse: separate;
            border-spacing: 20px;
            margin-left: -20px;
        }

        td {
            background-color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: middle;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
        }

        .no {
            background: #1F7762;
            color: #FFFFFF;
            width: 180px;
        }

        .name {
            padding-top: 20px;
            padding-bottom: 20px;
            width: 690px;
            cursor: pointer;
        }

        .time {
            width: 135px;
        }

        .en {
            font-size: 18px;
            line-height: 25px;

            small {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .contents-td {
        padding: 20px;
    }

    .contents {
        width: 653px;
        margin-left: 325px;
        margin-top: 30px;
    }

    .block-title {
        padding-left: 15px;
        position: relative;
        text-align: left;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        margin-bottom: 20px;

        &:before {
            content: ' ';
            width: 4px;
            height: 18px;
            background: #333333;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -10px;
            line-height: 0;
        }
    }

    .block-content {
        padding-left: 10px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }

    .block-sub-title {
        margin-bottom: 20px;
        position: relative;
        line-height: 22px;

        &:after {
            content: ' ';
            width: 100%;
            left: 0;
            top: 50%;
            height: 1px;
            background-color: #666666;
            position: absolute;
            z-index: 0;
        }
    }

    .block-sub-title.long {
        &:after {
            display: none;
        }
        .block-sub-title-text{
            padding-right: 0;
        }
    }

    .block-sub-title-text {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        z-index: 1;
        padding-right: 5px;
    }

    .sc-btns {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        z-index: 1;
        padding-left: 5px;
        display: inline-block;
        float: right;
    }

    .sc-btn {
        background: #333333;
        font-size: 14px;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 20px;
        padding: 2px 18px;
        border-radius: 15px;
    }


</style>