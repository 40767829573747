<template>
    <div class="contact-container">
        <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/c-title.png">

        <div class="inputs">

            <input type="text" placeholder="姓名 NAME" v-model="query.name">
            <input type="text" placeholder="电话 PHONE" v-model="query.mobile">
            <input type="text" placeholder="课程类别 COURSE" v-model="query.type">

        </div>

        <button type="button" @click="submit" class="submit-btn">提交</button>
    </div>
</template>

<script>
    export default {
        name: "LContact",
        data() {
            return {
                query: {
                    name: '',
                    mobile: '',
                    type: '',
                }
            }
        },
        methods: {
            submit() {

                alert('提交成功！');

                this.query.name = ''
                this.query.mobile = ''
                this.query.type = ''
            }
        }

    }
</script>

<style scoped>
    .contact-container {
        background-color: #0a0909;
        padding: 1px;

        background-image: url("../assets/images/fashion/contact-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .title-img {
        margin-top: 60px;
        margin-bottom: 50px;
    }

    .inputs {
        margin-bottom: 80px;
    }

    input {
        padding: 15px 20px;
        background-color: transparent;
        border: 1px solid #F9F9F9;
        margin-right: 20px;
        width: 386px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }

    .submit-btn {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
        padding: 8px 66px;
        margin-bottom: 60px;
    }
</style>